// You can customize the template with the help of this file

//Api config options
const apiConfig = {
    api: {
      //url:  'https://qa-campaign-middleware-staging-d69b75b2f51b.herokuapp.com/v1/'
      url: 'https://backend.ai-autoblogger.com/v1/'
    },
    token: {
      url2: ''
    }
  }
  
  export default apiConfig
  
